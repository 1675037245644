<template >
    <div>
        <div class="app-modal__box">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0">Услуги</p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4">
                    <el-row :gutter="20">
                        <el-form
                            ref="form"
                            status-icon
                            :model="form"
                            :rules="rules"
                        >
                            <el-col :span="12">
                                <div class="app-form__group mb-4">
                                    <el-form-item
                                        prop="name"
                                        size="mini"
                                        label="Наименование"
                                    >
                                        <el-input
                                            placeholder="Наименование"
                                            v-model="form.name"
                                            size="medium"
                                        ></el-input>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <!-- end col -->
                            <el-col :span="12">
                                <div class="app-form__group mb-4">
                                    <el-form-item
                                        prop="slug"
                                        size="mini"
                                        label="Услуги полного имени"
                                    >
                                        <el-input
                                            placeholder="Услуги полного имени"
                                            v-model="form.slug"
                                            size="medium"
                                        ></el-input>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="12">
                                <div class="app-form__group mb-4">
                                    <el-form-item
                                        prop="slug"
                                        size="mini"
                                        label="Код"
                                    >
                                        <el-input
                                            placeholder="Код"
                                            v-model="form.slug"
                                            size="medium"
                                        ></el-input>
                                    </el-form-item>
                                </div>
                            </el-col>
                        </el-form>

                        <!-- end col -->
                    </el-row>
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {},
        };
    },
};
</script>
